<template>
  <div class="temple-order-list">
    <div class="order-list flex">
      <div class="list-img-box">
<!--        <van-image-->
<!--          round-->
<!--          width="80"-->
<!--          fit="cover"-->
<!--          :src="require('@/assets/lianhuadeng.png')"-->
<!--        />-->
        <img :src="require('@/assets/lianhuadeng1.png')" alt="" >
      </div>
      <div class="pray-box">
        <div class="pray-name">
<!--          {{ (order.orderPrayName && order.orderPrayName !== '') ? `${order.orderPrayName}供灯祈福, 登记${order.orderPrice}元, 愿${order.orderPrayComment}` : '香客供灯祈福, 登记' + order.orderPrice + '元, 愿${order.orderPrayComment}' }}-->
          {{
            (() => {
              const prayName = order.orderPrayName && order.orderPrayName !== '' ? order.orderPrayName : '香客';
              return `${prayName}供灯祈福, 登记${order.orderPrice}元, 愿${order.orderPrayComment}`;
            })()
          }}
        </div>
        <div class="pray-time">
          {{ order.orderAddtime }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'TempleOrderList',
  props: {
    order: Object,
  },
  data () {
    return {}
  },
  components: {
    comtent(){
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.temple-order-list {
  margin: 5px 10px 10px 10px;
  width: 100vw;
  height: 70px;
  border-bottom: 1px solid #eee;

  .order-list {
    .list-img-box {
      margin: 0 10px 10px 0;
      width: 20%;
    }

    .pray-box {
      padding-right: 10px;
      width: 70%;

      .pray-name {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC, sans-serif;
        font-weight: 400;
      }

      .pray-time {
        padding-top: 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC, sans-serif;
        font-weight: 400;
        margin-bottom: 15px;
      }
    }

  }
}

</style>
